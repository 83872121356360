import 'bootstrap/dist/css/bootstrap.css';
import * as bootstrap from 'bootstrap';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import Swal from 'sweetalert2';
import $ from 'jquery';
import * as PIXI from 'pixi.js';
import * as textData from "../static/text.json";
// import * as WebGL from '../node_modules/three/examples/jsm/capabilities/WebGL.js';
import { OrbitControls } from '../node_modules/three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from '../node_modules/three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from '../node_modules/three/examples/jsm/loaders/DRACOLoader.js';
import { EffectComposer } from '../node_modules/three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from '../node_modules/three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from '../node_modules/three/examples/jsm/postprocessing/ShaderPass.js';
import { SavePass } from '../node_modules/three/examples/jsm/postprocessing/SavePass.js';
import { FXAAShader } from '../node_modules/three/examples/jsm/shaders/FXAAShader.js';
import { BlendShader } from '../node_modules/three/examples/jsm/shaders/BlendShader.js';
import { CopyShader } from '../node_modules/three/examples/jsm/shaders/CopyShader.js';
import { UnrealBloomPass } from '../node_modules/three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { GUI } from '../node_modules/three/examples/jsm/libs/lil-gui.module.min.js';

const sprites = [];
const cameraHeight = 2;
const cudeHeight = 0.2;
const ENTIRE_SCENE = 0;
const BLOOM_SCENE = 1;
const modal = new bootstrap.Modal('#pop-up-container', {})

let mapStat = false;
let introPaint, introLogo, introLogoArea;
let pixelRatio;
let container;
let camera, controls, scene, renderer, spots, tween, composer, finalComposer, fxaaPass, blurPass, savePass, blendPass, outputPass;
let wallMeshs = []
let paintMeshs = [];
const bloomLayer = new THREE.Layers();
const darkMaterial = new THREE.MeshBasicMaterial( { color: 'black' } );
const materials = {};
const params = {
  exposure: 1,
  bloomStrength: 0.66,
  bloomThreshold: 0,
  bloomRadius: 0,
  scene: 'Scene with Glow'
};
let currentPosIndex = 0;
let orderPath = [0, 1, 2, 3, 4, 7, 6, 5, 4, 3, 8, 20, 18, 19, 15, 14, 12, 11, 10, 9, 20, 17, 16];
let isForceWalking = false;

if ((isFacebookApp() && isIOSUser()) || !isWebGLAvailable()) {
  document.getElementById('not-support-overlay').style.display = "flex";
} else {
  startProcess()
}

function startProcess() {
  try {
    setTimeout(function() {
      init();
      render();
      animate();
      initMap();
    }, 5000);
  } catch (error) {
    
    // Show overlay if broswer not support
    document.getElementById('not-support-overlay').style.display = "flex";
    alert(error);
  }
}

function isFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1) || (ua.indexOf("Instagram") > -1);
}

function init() {

  camera = new THREE.PerspectiveCamera(
    60,
    window.innerWidth / window.innerHeight,
    0.1,
    100
  );

  // WebGL Render Config
  // renderer = new THREE.WebGLRenderer({ 
  //   antialias: true,
  //   powerPreference: "high-performance" 
  // });
  renderer = new THREE.WebGL1Renderer({ 
    antialias: true,
    powerPreference: "high-performance" 
  });
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.antialias = true;
  renderer.physicallyCorrectLights = true;
  renderer.shadowMap = THREE.PCFSoftShadowMap;
  renderer.toneMapping = THREE.ReinhardToneMapping;
  // renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.autoClear = false
  renderer.precision = true;
  renderer.outputEncoding = THREE.sRGBEncoding;


  document.getElementById("container").appendChild(renderer.domElement);
  container = document.getElementById( 'container' );

  scene = new THREE.Scene();
  // scene.background = new THREE.Color( 0xffffff );

  // Player Controller init
  controls = new OrbitControls(camera, renderer.domElement);
  controls.enableZoom = false;
  controls.enableDamping = true;
  controls.dampingFactor = 0.2;

  controls.addEventListener('end', updateCameraOrbit);
  window.addEventListener('resize',onWindowResize);

  bloomLayer.set( BLOOM_SCENE );

  const renderScene =  new RenderPass(scene, camera);

  const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85);
  bloomPass.threshold = params.bloomThreshold;
  bloomPass.strength = params.bloomStrength;
  bloomPass.radius = params.bloomRadius;

  fxaaPass = new ShaderPass( FXAAShader );

  // save pass

  const renderTargetParameters = {
    minFilter: THREE.LinearFilter,
    magFilter: THREE.LinearFilter,
    stencilBuffer: false
  };

  savePass = new SavePass(
    new THREE.WebGLRenderTarget(
      window.innerWidth,
      window.innerHeight,
      renderTargetParameters
    )
  );

  blendPass = new ShaderPass(BlendShader, "tDiffuse1");
  blendPass.uniforms["tDiffuse2"].value = savePass.renderTarget.texture;
  blendPass.uniforms["mixRatio"].value = 0;

  outputPass = new ShaderPass(CopyShader);
  outputPass.renderToScreen = true;


  blurPass = new ShaderPass ({

    uniforms: {
      tDiffuse: { type: 't', value: null },
      tColor: { type: 't', value: null },
      resolution: { type: 'v2', value: new THREE.Vector2( 1, 1 ) },
      viewProjectionInverseMatrix: { type: 'm4', value: new THREE.Matrix4() },
      previousViewProjectionMatrix: { type: 'm4', value: new THREE.Matrix4() },
      velocityFactor: { type: 'f', value: 1 }
    },
    vertexShader: document.getElementById( 'vs-motionBlur' ).textContent,
    fragmentShader: document.getElementById( 'fs-motionBlur' ).textContent
  });
  blurPass.renderToScreen = true;

  composer = new EffectComposer(renderer);
  composer.renderToScreen = false;
  composer.addPass(renderScene);
  composer.addPass(bloomPass);

  const finalPass = new ShaderPass(
    new THREE.ShaderMaterial( {
      uniforms: {
        baseTexture: { value: null },
        bloomTexture: { value: composer.renderTarget2.texture }
      },
      vertexShader: document.getElementById( 'vertexshader' ).textContent,
      fragmentShader: document.getElementById( 'fragmentshader' ).textContent,
      defines: {}
    } ), 'baseTexture'
  );
  finalPass.needsSwap = true;
  pixelRatio = renderer.getPixelRatio();
  fxaaPass.material.uniforms['resolution'].value.x = 1 / ( container.offsetWidth * pixelRatio );
  fxaaPass.material.uniforms['resolution'].value.y = 1 / ( container.offsetHeight * pixelRatio );

  finalComposer = new EffectComposer( renderer );
  finalComposer.addPass( renderScene );
  finalComposer.addPass( finalPass );
  // finalComposer.addPass(blurPass);
  finalComposer.addPass(blendPass);
  finalComposer.addPass(savePass);
  finalComposer.addPass(outputPass);
  finalComposer.addPass(fxaaPass);

  const gui = new GUI();
  gui.hide();

  gui.add( params, 'scene', [ 'Scene with Glow', 'Glow only', 'Scene only' ] ).onChange( function ( value ) {

    switch ( value ) 	{

      case 'Scene with Glow':
        composer.renderToScreen = false;
        break;
      case 'Glow only':
        composer.renderToScreen = true;
        break;
      case 'Scene only':
        // nothing to do
        break;

    }

    render();

  } );

  const folder = gui.addFolder( 'Bloom Parameters' );

  folder.add( params, 'exposure', 0.1, 2 ).onChange( function ( value ) {

    renderer.toneMappingExposure = Math.pow( value, 4.0 );
    render();

  } );

  folder.add( params, 'bloomThreshold', 0.0, 1.0 ).onChange( function ( value ) {

    bloomPass.threshold = Number( value );
    render();

  } );

  folder.add( params, 'bloomStrength', 0.0, 10.0 ).onChange( function ( value ) {

    bloomPass.strength = Number( value );
    render();

  } );

  folder.add( params, 'bloomRadius', 0.0, 1.0 ).step( 0.01 ).onChange( function ( value ) {

    bloomPass.radius = Number( value );
    render();

  } );

  buildScene();
  updateCameraOrbit();

  const myModalEl = document.getElementById('pop-up-container')

  myModalEl.addEventListener('hidden.bs.modal', event => {
    $('#pop-up-container #image').attr('src', ``);
    $('#pop-up-container .text').html('');
    $('#pop-up-container .author').text('');
    $('#pop-up-container .author_second').text('');
    $('#pop-up-container #introImg').attr('src', ``);
    $('#pop-up-container #otherOrganizerImg').attr('src', ``);
  })
}

function onWindowResize() {

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  fxaaPass.material.uniforms[ 'resolution' ].value.x = 1 / ( container.offsetWidth * pixelRatio );
  fxaaPass.material.uniforms[ 'resolution' ].value.y = 1 / ( container.offsetHeight * pixelRatio );

  renderer.setSize( window.innerWidth, window.innerHeight );
  composer.setSize( window.innerWidth, window.innerHeight );
  finalComposer.setSize( window.innerWidth, window.innerHeight );

  render();

}

function onclick(event) {

  if (isForceWalking) {
    return;
  }

  // Raycaster - Track object from camera click event
  let selectedObject;
  let raycaster = new THREE.Raycaster();
  let mouse = new THREE.Vector2();
  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

  raycaster.setFromCamera(mouse, camera);

  let paintAndWallMeshs = paintMeshs.concat(wallMeshs);
  let items = paintAndWallMeshs.concat(spots);
  items = items.concat([introPaint, introLogo, introLogoArea]);
  let intersects = raycaster.intersectObjects(items);


  if (intersects.length > 0) {

    selectedObject = intersects.filter(function (res) {
      return res && res.object;
    })[0];

    if (selectedObject.object.name.indexOf("Wall") > -1 || selectedObject.object.name.indexOf("wall") > -1 || selectedObject.object.name.indexOf("Cube") > -1) {
      return;
    }

    $(".modal-content").removeClass("transparent-bg");

    if (selectedObject.object.name == "Intro") {

      $(".modal-content").addClass("transparent-bg");
      $('#pop-up-container #introImg').removeClass('logo');

      $('#pop-up-container #introContent').show();
      $('#pop-up-container #genericContent').hide();

      $('#pop-up-container #introImg').attr('src', `images/paints/intro.jpg`);
      
      modal.show();
    }

    if (selectedObject.object.name == "AllianzGI_Logo") {

      $('#pop-up-container #introImg').addClass('logo');

      $('#pop-up-container #introContent').show();
      $('#pop-up-container #genericContent').hide();

      $('#pop-up-container #introImg').attr('src', `images/logo.png`);
      $('#pop-up-container #otherOrganizerImg').attr('src', `images/Organizer.jpg`);
      
      modal.show();
    }

    if (selectedObject.object.name.indexOf("Paint") > -1) {
      $('#pop-up-container #introContent').hide();
      $('#pop-up-container #genericContent').show();

      let fileName = selectedObject.object.name.replace("Paint_", "");
      let data = textData.data.filter(function(item) {
        return item.gltfOrder == fileName;
      });

      if (!data.length) {
        return;
      }

      if (fileName == '02') {
        fileName = '03';

        data = textData.data.filter(function(item) {
          return item.gltfOrder == 3;
        });
      } else if (fileName == '03') {
        fileName = '02';

        data = textData.data.filter(function(item) {
          return item.gltfOrder == 2;
        });
      }

      data = data[0];

      $("#image-wrapper").removeClass("col-lg-5");
      $("#text-wrapper").removeClass("col-lg-7");
      $("#image-wrapper").removeClass("col-lg-6");
      $("#text-wrapper").removeClass("col-lg-6");

      if (data.mode == "Portrait") {
        $("#image-wrapper").addClass("col-lg-5");
        $("#text-wrapper").addClass("col-lg-7");
      } else {
        $("#image-wrapper").addClass("col-lg-6");
        $("#text-wrapper").addClass("col-lg-6");
      }
      
      $('#pop-up-container #image').attr('src', `images/paints/${fileName}.jpg`);
      $('#pop-up-container .text').html(data.descriptions);
      $('#pop-up-container .author').text(data.displayNameFirst);
      $('#pop-up-container .author_second').text(data.displayNameSecond);
      
      modal.show();

      return;
    }

    if (selectedObject.object.name.indexOf("spot") > -1) {
      var newPos = selectedObject.object.position.clone();
      newPos.y = camera.position.y;
      tween = new TWEEN.Tween(camera.position).to(
        {
          x: newPos.x,
          y: cameraHeight,
          z: newPos.z
        },
        2000
      );

      tween.easing(TWEEN.Easing.Quadratic.Out);
      tween.start();
      tween.onUpdate(function () {
        updateCameraOrbit();
      }.bind(this));
      tween.onComplete(function () {
        spots.forEach(function(spot) {
          spot.layers.enable(BLOOM_SCENE);
        });
        selectedObject.object.layers.disable(BLOOM_SCENE);
        if (selectedObject.object.mapId) {
          sprites.forEach(function(sprite) {
            sprite.tint = 0xffffff;
          })

          var mapId = selectedObject.object.mapId;
          currentPosIndex = mapId
          sprites.filter(function(sprite) {
            return mapId == sprite.mapId
          })[0].tint = 0x00ffff
        }
        updateCameraOrbit();
      }.bind(this));
    }
    
  }
}

function updateCameraOrbit() {
  // Update OrbitControls target to a point just in front of the camera
  let forward = new THREE.Vector3();
  camera.getWorldDirection(forward);
  controls.target.set((camera.position.x + forward.x), cameraHeight, (camera.position.z + forward.z));
};

function render() {
  switch ( params.scene ) {

    case 'Scene only':
      renderer.render( scene, camera );
      break;
    case 'Glow only':
      renderBloom( false );
      break;
    case 'Scene with Glow':
    default:
      // render scene with bloom
      renderBloom( true );

      // render the entire scene, then render bloom scene on top
      finalComposer.render();
      break;
  }

  if (isForceWalking) {
    blendPass.uniforms["mixRatio"].value = 0.9;
  } else {
    blendPass.uniforms["mixRatio"].value = 0;
  }
  
}

function renderBloom( mask ) {

  if ( mask === true ) {

    scene.traverse( darkenNonBloomed );
    composer.render();
    scene.traverse( restoreMaterial );

  } else {

    camera.layers.set( BLOOM_SCENE );
    composer.render();
    camera.layers.set( ENTIRE_SCENE );

  }

}

function animate() {
  controls.update();
  TWEEN.update();
  requestAnimationFrame(animate);
  render();
}

function buildScene() {

  scene.traverse( disposeMaterial );
  scene.children.length = 0;
    
  let mat, geo;

  // spot location
  spots = [];
  let spotPos = [
        new THREE.Vector3(15.2, cudeHeight, 5.77),
        new THREE.Vector3(15.2, cudeHeight, 1.67),
        new THREE.Vector3(12.2, cudeHeight, -6.17),
        new THREE.Vector3(7.2, cudeHeight, -7.17),
        new THREE.Vector3(7.2, cudeHeight, -2.57),
        new THREE.Vector3(10.2, cudeHeight, 0.57),
        new THREE.Vector3(7.2, cudeHeight, 3.17),
        new THREE.Vector3(4.2, cudeHeight, 0.57),
        new THREE.Vector3(3.2, cudeHeight, -5.17),
        new THREE.Vector3(0.2, cudeHeight, 0.17),
        new THREE.Vector3(2.2, cudeHeight, 6.17),
        new THREE.Vector3(-2.2, cudeHeight, 4.17),
        new THREE.Vector3(-7.2, cudeHeight, 5.57),
        new THREE.Vector3(-7.2, cudeHeight, 5.57),
        new THREE.Vector3(-12.7, cudeHeight, 3.57),
        new THREE.Vector3(-14.2, cudeHeight, -0.57),
        new THREE.Vector3(-8.2, cudeHeight, 0.5),
        new THREE.Vector3(-4.5, cudeHeight, 0.5),
        new THREE.Vector3(-4.5, cudeHeight, -5.5),
        new THREE.Vector3(-8.5, cudeHeight, -5.5),
        new THREE.Vector3(-1.5, cudeHeight, -3.5),
    ];

  const spotTexture = new THREE.TextureLoader().load("texture/white_spot.png")
  spotTexture.wrapS = THREE.RepeatWrapping;
  spotTexture.wrapT = THREE.RepeatWrapping;
  spotTexture.repeat.set( 1, 1 );

  spotPos.forEach(function(pos, index) {
    geo = new THREE.CylinderGeometry(0.4, 0.4, 0.0001, 48);
    mat = new THREE.MeshBasicMaterial({ 
      wireframe: false,
      map: spotTexture,
      transparent: true
    });

    let spot = new THREE.Mesh(geo, mat);
    spot.position.x = pos.x
    spot.position.z = pos.z
    spot.position.y = 0
    spot.geometry.computeBoundingBox();
    spot.name = "spot"
    spot.mapId = index;
    scene.add(spot);
    spot.layers.enable(BLOOM_SCENE);
    spots.push(spot);
  });

  const logoBoxGeo = new THREE.BoxGeometry( 0.01, 2.5, 3 );
  const logoBoxMaterial = new THREE.MeshBasicMaterial( {
    color: 0x00ff00,
    wireframe: false,
    transparent: true
  });

  const logoBox = new THREE.Mesh( logoBoxGeo, logoBoxMaterial );
  logoBox.position.x = 12.2;
  logoBox.position.y = 2;
  logoBox.position.z = 5;
  logoBox.visible = false;
  logoBox.name = "AllianzGI_Logo";
  scene.add(logoBox);

  introLogoArea = logoBox

  const loader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath( 'libs/js/draco/' );
  loader.setDRACOLoader( dracoLoader );

  loader.setPath( 'models/gltf/' ).load( 'gallery.gltf', function ( gltf ) {
    $('.loading').fadeOut(2000);
    $('.map-conatiner').show();

    scene.add( gltf.scene );

    gltf.scene.traverse( function ( obj ) {

      if ( obj.material ) {

        if (obj.name.indexOf("Floor") > -1 || obj.name.indexOf("wall") > -1 || obj.name.indexOf("Wall") > -1) {
          obj.material.roughness = 0.7;
          obj.material.metalness = 0;

          if (obj.name.indexOf("Floor") == -1) {
            const whiteTextture = new THREE.TextureLoader().load("texture/white.jpg")
            whiteTextture.wrapS = THREE.RepeatWrapping;
            whiteTextture.wrapT = THREE.RepeatWrapping;

            if (!obj.material.map) {
              obj.material.map = whiteTextture;
            }
            
          }
        }
      }

      if (obj.name == "Intro") {
        introPaint = obj;
      }

      if (obj.name.indexOf("Cir_top_fin") > -1) {
        const whiteTextture = new THREE.TextureLoader().load("texture/white.jpg")
        whiteTextture.wrapS = THREE.RepeatWrapping;
        whiteTextture.wrapT = THREE.RepeatWrapping;

        if (!obj.material.map) {
          obj.material.map = whiteTextture;
        }
      }

      if (obj.name.indexOf("Curve_light") > -1 ) {
        obj.layers.enable(BLOOM_SCENE);
      }

      // Lighthinh
      if (obj.name.indexOf("Point") > -1 ) {
        // obj.intensity = 25;
      }


      if (obj.material) {
         if (obj.material.name == "Floor_light") {
          obj.layers.enable(BLOOM_SCENE);
          // const whiteTextture = new THREE.TextureLoader().load("texture/blue.png")
          // obj.material.emissiveMap = whiteTextture;
         }
      }

      // init point for camera
      if (obj.name == "AllianzGI_Logo") {
        camera.position.set(obj.position.x + 4, cameraHeight, obj.position.z);
        camera.lookAt(obj.position.x, cameraHeight, obj.position.z - 1);
        introLogo = obj;
      }

      if (obj.name.indexOf("Wall") > -1 || obj.name.indexOf("wall") > -1 || obj.name.indexOf("Cube") > -1) {
        wallMeshs.push(obj);
      }

      if (obj.name.indexOf("Paint") > -1) {

        paintMeshs.push(obj);
      }
    });

    updateCameraOrbit();
    renderer.domElement.addEventListener("click", onclick, true);
  },
  function (xhr) {
    console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );

  },
  function (error) {
    console.log(error);
    console.log("An error happened");
    
    // alert(error);
  }
);

}

function darkenNonBloomed( obj ) {

  if ( obj.isMesh && bloomLayer.test( obj.layers ) === false ) {
    materials[ obj.uuid ] = obj.material;
    obj.material = darkMaterial;

  }

}

function restoreMaterial( obj ) {

  if ( materials[ obj.uuid ] ) {

    obj.material = materials[ obj.uuid ];
    delete materials[ obj.uuid ];

  }

}


function disposeMaterial( obj ) {

  if ( obj.material ) {

    obj.material.dispose();

  }
}

function initMap() {
  
  const containerRatio = 5;
  const width = 1505 / containerRatio;
  const height = 829 / containerRatio;
  
  const app = new PIXI.Application({ 
    width: width,
    height: height
  });
  document.getElementById("map").appendChild(app.view);

  PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

  const background = PIXI.Sprite.from('images/map.png')
  background.width = app.screen.width;
  background.height = app.screen.height;
  app.stage.addChild(background);

  const mapSpot = [
    {
      x: width - 35,
      y: height - 50,   
      index: 0,
      lookAt: new THREE.Vector3(12.214676856994629, 2.2698960304260254, 5.179863452911377)
    },
    {
      x: width - 35,
      y: height - 80,
      index: 1,
      lookAt: new THREE.Vector3(0,0,0)
    },
    {
      x: width - 50,
      y: height - 130,   
      index: 2,
      lookAt: new THREE.Vector3(15.062726974487305, 2.2200851440429688, -8.308134078979492)
    },
    {
      x: width - 95,
      y: height - 140,  
      index: 3,
      lookAt: new THREE.Vector3(4.042469024658203, 1.9139469861984253, -8.450146675109863)
    },
    {
      x: width - 95,
      y: height - 100,  
      index: 4,
      lookAt: new THREE.Vector3(7.17115592956543, 0.3391868472099304, 0.5624724626541138)
    },
    {
      x: width - 75,
      y: height - 80,  
      index: 5,
      lookAt: new THREE.Vector3(8.2, 2.039, 0.415)
    },
    {
      x: width - 95,
      y: height - 55,  
      index: 6,
      lookAt: new THREE.Vector3(6.9584059715271, 1.8057122230529785, 5.350945949554443)
    },
    {
      x: width - 115,
      y: height - 80,  
      index: 7,
      lookAt: new THREE.Vector3(6.175, 2.0806, 0.307)
    },
    {
      x: width - 125,
      y: height - 125,  
      index: 8,
      lookAt: new THREE.Vector3(1.102, 1.914, -6.887)
    },
    {
      x: width - 150,
      y: height - 80,  
      index: 9,
      lookAt: new THREE.Vector3(-1.551, 1.806, 1.336)
    },
    {
      x: width - 140,
      y: height - 30,  
      index: 10,
      lookAt: new THREE.Vector3(4.530, 1.806, 5.855)
    },
    {
      x: width - 180,
      y: height - 45, 
      index: 11,
      lookAt: new THREE.Vector3(-1.945, 1.806, 6.673)
    },
    {
      x: width - 230,
      y: height - 35, 
      index: 12,
      lookAt: new THREE.Vector3(-7.920, 1.914, 7.933)
    },
    {
      x: width - 270,
      y: height - 85, 
      index: 15,
      lookAt: new THREE.Vector3(-17.419, 1.914, 0.120)
    },
    {
      x: width - 260,
      y: height - 55, 
      index: 14,
      lookAt: new THREE.Vector3(-10.459, 1.914, 2.891)
    },
    {
      x: width - 225,
      y: height - 80, 
      index: 16,
      lookAt: new THREE.Vector3(-2.182, 1.806, -0.360)
    },
    {
      x: width - 190,
      y: height - 80, 
      index: 17,
      lookAt: new THREE.Vector3(-2.182, 1.806, -0.360)
    },
    {
      x: width - 170,
      y: height - 120, 
      index: 20,
      lookAt: new THREE.Vector3(-0.841, 1.914, -7.338)
    },
    {
      x: width - 190,
      y: height - 140, 
      index: 18,
      lookAt: new THREE.Vector3(-3.389, 1.914, -9.956)
    },
    {
      x: width - 230,
      y: height - 130, 
      index: 19,
      lookAt: new THREE.Vector3(-12.278, 1.914, -6.670)
    },
  ]
  
  mapSpot.forEach(function (spot) {

    const sprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    sprite.tint = 0xffffff;
    sprite.width = 15;
    sprite.height = 15;
    sprite.x = spot.x
    sprite.y = spot.y
    sprite.mapId = spot.index;
    sprite.lookAt = spot.lookAt;
    sprite.interactive = true;
    sprite.buttonMode = true;

    sprite.on('click', function() {
      teleport(sprite);
      
    });
    sprite.on('tap', function() {
      teleport(sprite);
    });

    sprites.push(sprite);
    app.stage.addChild(sprite);
  });

  sprites[0].tint = 0x00ffff;

  const entryLabel = new PIXI.Text("Entrance", {
    fontSize: 10,
    fill: 0xffffff
  });

  entryLabel.x = width - 49
  entryLabel.y = height - 30

  app.stage.addChild(entryLabel);


  // Show Hide Map Event
  $("#map-switch").on("click", function() {
    
    if (mapStat) {
      $(this).css("background-image", "url(images/Map_Off.png)");
      $("#map").show();
    } else {
      $(this).css("background-image", "url(images/Map_On.png)");
      $("#map").hide();
    }

    mapStat = !mapStat;
  });
}

function teleport(sprite) {

  isForceWalking = true

  sprites.forEach(function(sprite) {
    sprite.tint = 0xffffff;
  })
  sprite.tint = 0x00ffff

  const index = sprite.mapId;

  if (spots[index]) {

    let newPos = spots[index].position.clone();
    newPos.y = camera.position.y;
    tween = new TWEEN.Tween(camera.position).to(
      {
        x: newPos.x,
        y: cameraHeight,
        z: newPos.z
      },
      1000
    );

    const startQuaternion = camera.quaternion.clone(); //set initial angle
    camera.lookAt(sprite.lookAt);
    const endQuaternion = camera.quaternion.clone(); //set destination angle
    camera.quaternion.copy(startQuaternion);

    tween.easing(TWEEN.Easing.Quadratic.Out);
    tween.start();
    tween.onUpdate(function () {
      camera.quaternion.slerp(endQuaternion, 0.01);
      updateCameraOrbit();
    }.bind(this));
    tween.onComplete(function () {
      
      spots.forEach(function(spot) {
        spot.layers.enable(BLOOM_SCENE);
      });
      spots[index].layers.disable(BLOOM_SCENE);
      // camera.lookAt(sprite.lookAt);
      updateCameraOrbit();
      isForceWalking = false;
      
    }.bind(this));
  }
  
}

// function getCurrentSpot() {
//   return orderPath[currentPosIndex];
// }

// async function teleport(sprite) {

//   sprites.forEach(function(sprite) {
//     sprite.tint = 0xffffff;
//   })

//   const index = sprite.mapId;
//   // const currentMapId = getCurrentSpot();
//   // const targetIndex = orderPath.lastIndexOf(index);
//   const time = 500;
//   let pathArr = [];

//   if (currentPosIndex < index) {
//     pathArr = orderPath.slice(currentPosIndex, index + 1);
//   } else {
//     pathArr = orderPath.slice(index, currentPosIndex + 1);
//     pathArr = pathArr.reverse();
//   }

//   console.log("Start :  " + currentPosIndex);
//   console.log("Path:  " + pathArr);
//   console.log("End :  " + index);

//   isForceWalking = true;
//   for (const result of pathArr) {
  
//     let newPos = spots[result].position.clone();
//     newPos.y = camera.position.y;

//     const startQuaternion = camera.quaternion.clone(); //set initial angle
//     camera.lookAt(newPos);
//     const endQuaternion = camera.quaternion.clone(); //set destination angle
//     camera.quaternion.copy(startQuaternion);

//     tween = new TWEEN.Tween(camera.position).to(
//       {
//         x: newPos.x,
//         y: cameraHeight,
//         z: newPos.z,
//       },
//       time
//     );

//     // tween.easing(TWEEN.Easing.Quadratic.Out);
//     tween.start();

//     tween.onUpdate(function () {
//       camera.quaternion.slerp(endQuaternion, 0.1);
//       updateCameraOrbit();
//     }.bind(this));
//     tween.onComplete(function () {
//       updateCameraOrbit();
//     }.bind(this));
    
//     await delay(time);
//   }

//   sprite.tint = 0x00ffff;
//   currentPosIndex = index;
//   isForceWalking = false;
// }

function delay(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

function isWebGLAvailable() {

  try {

      const canvas = document.createElement( 'canvas' );
      return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );

  } catch ( e ) {

      return false;

  }

}

function isIOSUser(){
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform);
}